export const getElder = /* GraphQL */ `
  query GetElder($id: ID!) {
    getElder(id: $id) {
      id
      username
      status
      clientId
      county
      deliveryGroupId
      sortOrder
      name
      nickname
      birthday
      gender
      phoneNumber
      phoneNumber2
      phoneNumber3
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      email
      identificationCardId
      serviceCode
      noteForDelivery
      noteForMeal
      noteForHealth
      note
      noteOther
      preferDeliveryTimes
      isAllowedToEnterHome
      otherSocialServices
      isDisadvantaged
      relationshipToClient
      emergencyContact {
        name
        relationship
        phoneNumber
        phoneNumber2
      }
      govQualifiedCount
      govQualifiedPrice
      supervisorId
      linkGroupName
      outOfPocket
      feeCollectMethod
      disadvantagedTypes
      disadvantagedTypesHistory {
        approvedDate
        disadvantagedTypes
      }
      disabilityLevel
      cmsLevel
      caseNumber
      lunchRepeatOn
      dinnerRepeatOn
      fixedDeliveryStaffFee
      onTimeDelivery
      dementiaQuestionnaire
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
