import JSZip from 'jszip';
import { buildColumnsArray } from './helpers';
import { download } from 'utilities/file';
import ExcelJS from 'exceljs';
import { formatAddress } from 'utilities/format';
import { request } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';

const COUNTY = '屏東縣';

const remoteArea = ['三地門鄉', '霧臺鄉', '瑪家鄉', '泰武鄉', '來義鄉', '春日鄉', '獅子鄉', '牡丹鄉', '滿州鄉', '琉球鄉'];

export default async function writePingtung({
  elders: allElders,
  orders: allOrders,
  deliveryStaffs,
  selectedMonth = 'YYYY-MM',
  clientName,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
}) {
  const elders = allElders.filter(({ address }) => address.county === COUNTY);
  // const elders = allElders; // test
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
  }
  let orders = allOrders
    .filter(({ elderId }) => elders.some(({ id }) => id === elderId))
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
  }

  const restaurantIds = [];
  orders.forEach(({ restaurantId }) => {
    if (restaurantId && !restaurantIds.includes(restaurantId)) {
      restaurantIds.push(restaurantId);
    }
  });

  const restaurants = await Promise.all(restaurantIds.map(async (id) => {
    const { data: { getRestaurant: data } } = await request( /* GraphQL */ `
      query GetRestaurant($id: ID!) {
        getRestaurant(id: $id) {
          id
          name
          address {
            county
            district
            street
          }
        }
      }
    `, { id });
    return data;
  }));

  const month = selectedMonth.split('-')[1];

  const zip = new JSZip();

  const workbookDeliveryStaff = new ExcelJS.Workbook();
  const worksheetDeliveryStaff = workbookDeliveryStaff.addWorksheet(`${month}月`);
  getWorksheetDeliveryStaff(worksheetDeliveryStaff, elders, orders, deliveryStaffs, restaurants, selectedMonth, clientName);
  try {
    if (workbookDeliveryStaff && workbookDeliveryStaff.worksheets && workbookDeliveryStaff.worksheets.length > 0) {
      const bufferDataByDistrict = await workbookDeliveryStaff.xlsx.writeBuffer();
      zip.file(`${selectedMonth}送餐志工交通費印領清冊.xlsx`, bufferDataByDistrict, { binary: true });
    }
  } catch (e) {
    console.error(e);
  }

  // const workbookElder = new ExcelJS.Workbook();
  // const worksheet = workbookElder.addWorksheet('照顧組合服務費用項目清冊');
  // getWorksheetElder(worksheet, elders, orders, deliveryStaffs, restaurants, selectedMonth, clientName);
  // try {
  //   if (workbookElder && workbookElder.worksheets && workbookElder.worksheets.length > 0) {
  //     const bufferDataByDistrict = await workbookElder.xlsx.writeBuffer();
  //     zip.file(`${selectedMonth}照顧組合服務費用項目清冊.xlsx`, bufferDataByDistrict, { binary: true });
  //   }
  // } catch (e) {
  //   console.error(e);
  // }

  const files = Object.keys(zip.files);
  if (files.length !== 0) {
    const zipContent = zip.generate({ type: 'blob' });
    download(`${clientName}__${selectedMonth}核銷報表.zip`, zipContent);
  }
}

function getWorksheetDeliveryStaff(worksheet, elders = [], orders = [], deliveryStaffs, restaurants, selectedMonth, clientName) {
  const [year, month] = selectedMonth.split('-');
  const ROCYear = parseInt(year) - 1911;
  const deliveryStaffRows = [];
  let total = 0;
  orders.sort((a, b) => {
    if (a.deliveryStaffId < b.deliveryStaffId) return 1;
    if (a.deliveryStaffId < b.deliveryStaffId) return -1;
    if (a.restaurantId < b.restaurantId) return 1;
    return -1;
  }).forEach((order) => {
    const deliveryStaffRowIndex = deliveryStaffRows.findIndex(
      (row) => row.deliveryStaffInfo.id === order.deliveryStaffId && row.restaurantInfo.id === order.restaurantId);
    const elder = elders.find(({ id }) => id === order.elderId);
    if (deliveryStaffRowIndex === -1) {
      const deliveryStaff = deliveryStaffs.find(({ id }) => id === order.deliveryStaffId);
      const restaurant = restaurants.find(({ id }) => id === order.restaurantId);
      const formatBirthday = (birthday) => {
        if (!birthday) return '';
        const [year, month, day] = birthday.split('-');
        const ROCYear = parseInt(year) - 1911;
        return `${ROCYear}/${month}/${day}`;
      };
      deliveryStaffRows.push({
        deliveryStaffInfo: {
          id: order.deliveryStaffId,
          name: deliveryStaff.name,
          birthday: formatBirthday(deliveryStaff.birthday),
          identificationCardId: deliveryStaff.identificationCardId || '',
          address: formatAddress(deliveryStaff.address) || '',
        },
        restaurantInfo: {
          id: order.restaurantId,
          name: restaurant.name,
          address: formatAddress(restaurant.address) || '',
        },
        elderInfos: [{
          id: order.elderId,
          name: elder.name,
          address: formatAddress(elder.address, { includeZipCode: false }),
          lunchCount: order.mealSlot === 'lunch' ? 1 : 0,
          dinnerCount: order.mealSlot === 'dinner' ? 1 : 0,
        }],
        subtotal: remoteArea.includes(elder.address.district) ? 150 : 125,
      });
    } else {
      const elderInfoIndex = deliveryStaffRows[deliveryStaffRowIndex].elderInfos.findIndex(({ id }) => id === order.elderId);
      if (elderInfoIndex === -1) {
        deliveryStaffRows[deliveryStaffRowIndex].elderInfos.push({
          id: order.elderId,
          name: elder.name,
          address: formatAddress(elder.address, { includeZipCode: false }),
          lunchCount: order.mealSlot === 'lunch' ? 1 : 0,
          dinnerCount: order.mealSlot === 'dinner' ? 1 : 0,
        });
      } else {
        if (order.mealSlot === 'lunch') {
          deliveryStaffRows[deliveryStaffRowIndex].elderInfos[elderInfoIndex].lunchCount += 1;
        } else {
          deliveryStaffRows[deliveryStaffRowIndex].elderInfos[elderInfoIndex].dinnerCount += 1;
        }
      }
      deliveryStaffRows[deliveryStaffRowIndex].subtotal += remoteArea.includes(elder.address.district) ? 150 : 125;
    }
    total += remoteArea.includes(elder.address.district) ? 150 : 125;
  });

  const fontName = 'Calibri';
  const range = 'A:AZ';
  const columnArray = buildColumnsArray(range);

  let rowIndex = 1;

  const columnWidth = [3.33, 13.33, 14.17, 10.83, 40, 6.83, 7.5, 9.33, 9.33, 9.33, 10.33];
  columnWidth.forEach((_, index) => {
    worksheet.getColumn(columnArray[index]).width = columnWidth[index];
  });

  const defaultBorder = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };

  worksheet.mergeCells(`A${rowIndex}:K${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = `${ROCYear}年度長照服務發展基金獎助-長期照顧十年計畫2.0-營養餐飲服務`;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 16, name: fontName };
  worksheet.getCell(`A${rowIndex}`).border = defaultBorder;
  worksheet.getRow(rowIndex).height = 43.25;
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:K${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = `單位名稱：${clientName}          ${ROCYear}年${month}月志工服務及交通費印領清冊 `;
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 14, name: fontName };
  worksheet.getCell(`A${rowIndex}`).border = defaultBorder;
  worksheet.getRow(rowIndex).height = 37.25;
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:A${rowIndex + 1}`);
  worksheet.getCell(`A${rowIndex}`).value = '序\n號';
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
  worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`A${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`B${rowIndex}:B${rowIndex + 1}`);
  worksheet.getCell(`B${rowIndex}`).value = '志工姓名\n出生年月日\n身分證字號\n地址';
  worksheet.getCell(`B${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
  worksheet.getCell(`B${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`B${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`C${rowIndex}:C${rowIndex + 1}`);
  worksheet.getCell(`C${rowIndex}`).value = '中央廚房\n或\n合作餐飲業者地址';
  worksheet.getCell(`C${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
  worksheet.getCell(`C${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`C${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`D${rowIndex}:D${rowIndex + 1}`);
  worksheet.getCell(`D${rowIndex}`).value = '個案姓名';
  worksheet.getCell(`D${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`D${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`D${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`E${rowIndex}:E${rowIndex + 1}`);
  worksheet.getCell(`E${rowIndex}`).value = '個案地址';
  worksheet.getCell(`E${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`E${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`E${rowIndex}`).border = defaultBorder;
  worksheet.mergeCells(`F${rowIndex}:G${rowIndex}`);
  worksheet.getCell(`F${rowIndex}`).value = '送餐天數';
  worksheet.getCell(`F${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`F${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`F${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`F${rowIndex + 1}`).value = '中餐';
  worksheet.getCell(`F${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`F${rowIndex + 1}`).font = { size: 12, name: fontName };
  worksheet.getCell(`F${rowIndex + 1}`).border = defaultBorder;
  worksheet.getCell(`G${rowIndex + 1}`).value = '晚餐';
  worksheet.getCell(`G${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`G${rowIndex + 1}`).font = { size: 12, name: fontName };
  worksheet.getCell(`G${rowIndex + 1}`).border = defaultBorder;

  worksheet.mergeCells(`H${rowIndex}:J${rowIndex}`);
  worksheet.getCell(`H${rowIndex}`).value = '非偏鄉總計=(中餐+晚餐)x125\n偏鄉總計=(中餐+晚餐)x150';
  worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
  worksheet.getCell(`H${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`H${rowIndex}`).border = defaultBorder;
  worksheet.getCell(`H${rowIndex + 1}`).value = '總計';
  worksheet.getCell(`H${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`H${rowIndex + 1}`).font = { size: 12, name: fontName };
  worksheet.getCell(`H${rowIndex + 1}`).border = defaultBorder;
  worksheet.getCell(`I${rowIndex + 1}`).value = '自籌金額';
  worksheet.getCell(`I${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`I${rowIndex + 1}`).font = { size: 12, name: fontName };
  worksheet.getCell(`I${rowIndex + 1}`).border = defaultBorder;
  worksheet.getCell(`J${rowIndex + 1}`).value = '補助金額';
  worksheet.getCell(`J${rowIndex + 1}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`J${rowIndex + 1}`).font = { size: 12, name: fontName };
  worksheet.getCell(`J${rowIndex + 1}`).border = defaultBorder;
  worksheet.mergeCells(`K${rowIndex}:K${rowIndex + 1}`);
  worksheet.getCell(`K${rowIndex}`).value = '簽章';
  worksheet.getCell(`K${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`K${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`K${rowIndex + 1}`).border = defaultBorder;

  worksheet.getRow(rowIndex).height = 36;
  worksheet.getRow(rowIndex + 1).height = 28;
  rowIndex += 2;

  deliveryStaffRows.forEach(({ deliveryStaffInfo, restaurantInfo, elderInfos, subtotal }, index) => {
    const rowCount = elderInfos.length;
    if (rowCount > 1) {
      worksheet.mergeCells(`A${rowIndex}:A${rowIndex + rowCount - 1}`);
    }
    worksheet.getCell(`A${rowIndex}`).value = index + 1;
    worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`A${rowIndex}`).border = defaultBorder;
    if (rowCount > 1) {
      worksheet.mergeCells(`B${rowIndex}:B${rowIndex + rowCount - 1}`);
    }
    worksheet.getCell(`B${rowIndex}`).value =
      `${deliveryStaffInfo.name}\n${deliveryStaffInfo.birthday}\n${deliveryStaffInfo.identificationCardId}\n${deliveryStaffInfo.address}`;
    worksheet.getCell(`B${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    worksheet.getCell(`B${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`B${rowIndex}`).border = defaultBorder;
    if (rowCount > 1) {
      worksheet.mergeCells(`C${rowIndex}:C${rowIndex + rowCount - 1}`);
    }
    worksheet.getCell(`C${rowIndex}`).value = `${restaurantInfo.name}\n${restaurantInfo.address}`;
    worksheet.getCell(`C${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
    worksheet.getCell(`C${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`C${rowIndex}`).border = defaultBorder;
    elderInfos.sort(sortBy('name')).forEach((elderInfo, index) => {
      worksheet.getCell(`D${rowIndex + index}`).value = elderInfo.name;
      worksheet.getCell(`D${rowIndex + index}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      worksheet.getCell(`D${rowIndex + index}`).font = { size: 12, name: fontName };
      worksheet.getCell(`D${rowIndex + index}`).border = defaultBorder;
      worksheet.getCell(`E${rowIndex + index}`).value = elderInfo.address;
      worksheet.getCell(`E${rowIndex + index}`).alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
      worksheet.getCell(`E${rowIndex + index}`).font = { size: 12, name: fontName };
      worksheet.getCell(`E${rowIndex + index}`).border = defaultBorder;
      worksheet.getCell(`F${rowIndex + index}`).value = elderInfo.lunchCount;
      worksheet.getCell(`F${rowIndex + index}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      worksheet.getCell(`F${rowIndex + index}`).font = { size: 12, name: fontName };
      worksheet.getCell(`F${rowIndex + index}`).border = defaultBorder;
      worksheet.getCell(`G${rowIndex + index}`).value = elderInfo.dinnerCount;
      worksheet.getCell(`G${rowIndex + index}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      worksheet.getCell(`G${rowIndex + index}`).font = { size: 12, name: fontName };
      worksheet.getCell(`G${rowIndex + index}`).border = defaultBorder;
    });
    if (rowCount > 1) {
      worksheet.mergeCells(`H${rowIndex}:H${rowIndex + rowCount - 1}`);
    }
    worksheet.getCell(`H${rowIndex}`).value = subtotal;
    worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    worksheet.getCell(`H${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`H${rowIndex}`).border = defaultBorder;
    if (rowCount > 1) {
      worksheet.mergeCells(`I${rowIndex}:I${rowIndex + rowCount - 1}`);
    }
    worksheet.getCell(`I${rowIndex}`).value = 0;
    worksheet.getCell(`I${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    worksheet.getCell(`I${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`I${rowIndex}`).border = defaultBorder;
    if (rowCount > 1) {
      worksheet.mergeCells(`J${rowIndex}:J${rowIndex + rowCount - 1}`);
    }
    worksheet.getCell(`J${rowIndex}`).value = subtotal;
    worksheet.getCell(`J${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    worksheet.getCell(`J${rowIndex}`).font = { size: 12, name: fontName };
    worksheet.getCell(`J${rowIndex}`).border = defaultBorder;
    if (rowCount > 1) {
      worksheet.mergeCells(`K${rowIndex}:K${rowIndex + rowCount - 1}`);
    }
    worksheet.getCell(`K${rowIndex}`).border = defaultBorder;
    rowIndex += rowCount;
  });

  worksheet.mergeCells(`A${rowIndex}:G${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = '合計';
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`A${rowIndex}`).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  worksheet.getCell(`H${rowIndex}`).value = total;
  worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`H${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`H${rowIndex}`).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  worksheet.getCell(`I${rowIndex}`).value = 0;
  worksheet.getCell(`I${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`I${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`I${rowIndex}`).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  worksheet.getCell(`J${rowIndex}`).value = total;
  worksheet.getCell(`J${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getCell(`J${rowIndex}`).font = { size: 12, name: fontName };
  worksheet.getCell(`J${rowIndex}`).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  worksheet.getCell(`K${rowIndex}`).border = { top: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };
  rowIndex += 2;

  worksheet.mergeCells(`H${rowIndex}:K${rowIndex}`);
  worksheet.getCell(`H${rowIndex}`).value = '製表日期：';
  worksheet.getCell(`H${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
  worksheet.getCell(`H${rowIndex}`).font = { size: 12, name: fontName };
  rowIndex += 1;

  worksheet.mergeCells(`A${rowIndex}:K${rowIndex}`);
  worksheet.getCell(`A${rowIndex}`).value = '承辦人：　　　　　　　業務主管：　　　　　　　會計：　　　　　　　負責人：';
  worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
  worksheet.getCell(`A${rowIndex}`).font = { size: 14, name: fontName };

  return worksheet;
}

// function getWorksheetElder(worksheet, elders = [], orders = [], deliveryStaffs, restaurants, selectedMonth, clientName) {
//   const fontName = 'Calibri';
//   const range = 'A:AZ';
//   const columnArray = buildColumnsArray(range);

//   let rowIndex = 1;

//   const columnWidth = [10.33, 14.33, 12.83, 24.83, 8.33, 21.83, 14.33, 45.67, 11.33, 11.33, 8.33, 14.33,
//     8.33, 14.33, 11.33, 14.33, 14.33, 14.33, 14.33, 14.33, 14.33, 14.33, 14.33,
//   ];
//   columnWidth.forEach((_, index) => {
//     worksheet.getColumn(columnArray[index]).width = columnWidth[index];
//   });

//   const defaultBorder = { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } };

//   worksheet.mergeCells(`A${rowIndex}:W${rowIndex}`);
//   worksheet.getCell(`A${rowIndex}`).value = `照顧組合服務費用項目清冊`;
//   worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle' };
//   worksheet.getCell(`A${rowIndex}`).font = { size: 18, name: fontName };
//   rowIndex += 1;

//   worksheet.getCell(`A${rowIndex}`).value = `案號：`;
//   worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
//   worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
//   rowIndex += 1;

//   worksheet.getCell(`A${rowIndex}`).value = `服務單位：${clientName}`;
//   worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
//   worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
//   rowIndex += 1;

//   worksheet.getCell(`A${rowIndex}`).value = `版號：`;
//   worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'middle' };
//   worksheet.getCell(`A${rowIndex}`).font = { size: 12, name: fontName };
//   worksheet.getCell(`W${rowIndex}`).value = `產製時間：2024/11/01 09:13`; // TODO
//   worksheet.getCell(`W${rowIndex}`).alignment = { horizontal: 'right', vertical: 'middle' };
//   worksheet.getCell(`W${rowIndex}`).font = { size: 12, name: fontName };
//   rowIndex += 1;

//   ['序號', '身分證號', '個案姓名', '採用計畫', 'CMS\n等級', '福利身分別',
//     '服務項目\n類別', '服務日期', '給(支)付\n價格', '原民區或離島支付價格', '次數',
//     '申報費用', '部分負擔比率', '部分負擔\n費用', '補助比率', '申請(補助)費用',
//     '原民區或離島申請(補助)費用', '實際補助\n金額', '服務當下\n居住縣市', '目前居住縣市',
//     '目前居住行政區', '照管專員', '服務人員'].forEach((header, index) => {
//     worksheet.getCell(`${columnArray[index]}${rowIndex}`).value = header;
//     worksheet.getCell(`${columnArray[index]}${rowIndex}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
//     worksheet.getCell(`${columnArray[index]}${rowIndex}`).font = { size: 12, name: fontName };
//     worksheet.getCell(`${columnArray[index]}${rowIndex}`).border = defaultBorder;
//   });

//   return worksheet;
// }
