/* eslint-disable quotes, comma-dangle */
import i18n from 'i18next';
import { getTranslatedOrderStatus } from 'utilities/translate';
import { cancellationReasons } from '@silvergatedelivery/constants';
import { ORDER_CATEGORY } from 'forms/AdminBulkOrderForm/helpers';

const translatedOrderStatus = getTranslatedOrderStatus();

const schema = {
  "type": "object",
  "required": [
    "restaurantId"
  ],
  "properties": {
    "status": {
      "type": "string",
      "title": "狀態",
      'enum': translatedOrderStatus.map(({ value }) => value),
      'enumNames': translatedOrderStatus.map(({ label }) => label),
      'default': translatedOrderStatus[0].value,
    },
    "category": {
      "type": ["string", "null"],
      "title": "訂單類別",
      "enum": ORDER_CATEGORY,
      "default": "送餐"
    },
    "tier": {
      "type": "number",
      "title": `${i18n.t('送餐')}層級`
    },
    "restaurantId": {
      "type": "string",
      "title": i18n.t('餐廳')
    },
    "deliveryStaffId": {
      "type": ["string", "null"],
      "title": i18n.t('送餐大使')
    },
    "deliveryStaffFee": {
      "type": "number",
      "title": `${i18n.t('送餐費用')} (保留 $0 的話，系統會根據距離自動計算)`,
      "default": 0
    },
    "mealItems": {
      "type": "array"
    },
    "noteForMeal": {
      "type": ["string", "null"],
      "title": `${i18n.t('餐點')}備註`
    },
    "noteForDelivery": {
      "type": ["string", "null"],
      "title": `${i18n.t('送餐')}備註`
    },
    "note": {
      "type": ["string", "null"],
      "title": "其他備註"
    }
  },
  "allOf": [
    {
      "if": {
        "properties": {
          "status": {
            "const": "cancelled"
          }
        }
      },
      "then": {
        "properties": {
          "cancellationReason": {
            "type": ["string", "null"],
            "title": "訂單取消原因",
            'enum': cancellationReasons.map(({ value }) => value),
            'enumNames': cancellationReasons.map(({ label }) => label),
            'default': null,
          },
          "cancellationNote": {
            "type": ["string", "null"],
            "title": "訂單取消備註",
          },
        },
        "required": [
          "cancellationReason",
        ]
      }
    }
  ]
};

export default schema;
