import moment from 'moment-timezone';
import { request } from 'utilities/graph';
import {
  getElderDeliveryGroupByDeliveryGroupId,
} from 'graphql/queries';
import {
  getElder,
} from './queries';

const getDatesBetween = (startDate, endDate) => {
  const dates = [];
  const currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(endDate, 'day')) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate.add(1, 'day');
  }

  return dates;
};

export const processNewRecurringOrderSetting = async (data) => {
  const {
    startOn, endOn, elderId, restaurantId, deliveryStaffId, deliveryGroupId, mealItems, repeatOn, deliveryBy,
  } = data;
  const hour = parseInt(deliveryBy.split(':')[0]);
  data.mealSlot = hour <= 14 ? 'lunch' : 'dinner';

  // 建立30天訂單
  const today = moment().tz('Asia/Taipei').format('YYYY-MM-DD');
  const oneMonthAfter = moment().add(30, 'days').tz('Asia/Taipei').format('YYYY-MM-DD');
  if (endOn && endOn < today) {
    return;
  }
  const start = startOn < today ? today : startOn;
  const end = (endOn && (endOn < oneMonthAfter)) ? endOn : oneMonthAfter;
  const toCreateOrders = [];
  const elders = [];
  if (start <= end) {
    const datesBetween = getDatesBetween(start, end);
    if (elderId) {
      const { data: { getElder: data } } = await request(getElder, { id: elderId });
      if (data) {
        elders.push(data);
      }
    }
    if (deliveryGroupId) {
      const { data: { getElderDeliveryGroupByDeliveryGroupId: { items } } } =
        await request(getElderDeliveryGroupByDeliveryGroupId, { deliveryGroupId });
      if (items && items.length !== 0) {
        const getElderWithSortOrder = async (elderId, sortOrder) => {
          const { data: { getElder: data } } = await request(getElder, { id: elderId });
          if (data && data.status === '使用中') {
            elders.push({
              ...data,
              deliveryGroupId,
              sortOrder,
            });
          }
        };
        await Promise.all(items.map(({ elderId, sortOrder }) => {
          return getElderWithSortOrder(elderId, sortOrder);
        }));
      }
    }
    const hour = parseInt(deliveryBy.split(':')[0]);
    const mealSlot = hour <= 14 ? 'lunch' : 'dinner';
    const total = mealItems.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.price * currentValue.quantity;
    }, 0);
    const totalCost = mealItems.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.cost * currentValue.quantity;
    }, 0);
    elders.forEach((elder) => {
      const deliveryDatetimes = [];
      datesBetween.forEach((date) => {
        const week = moment(date).day();
        if (repeatOn.includes(week)) {
          if ((mealSlot === 'lunch' && elder.lunchRepeatOn && elder.lunchRepeatOn.includes(week)) ||
            (mealSlot === 'dinner' && elder.dinnerRepeatOn && elder.dinnerRepeatOn.includes(week))) {
            const deliveryDatetime = moment.tz(`${date} ${deliveryBy}`, 'Asia/Taipei').toISOString();
            deliveryDatetimes.push(deliveryDatetime);
          }
        }
      });
      if (deliveryDatetimes.length !== 0) {
        toCreateOrders.push({
          elderId: elder.id,
          restaurantId,
          deliveryStaffId,
          mealItems,
          deliveryDatetimes,
          noteForDelivery: elder.noteForDelivery,
          noteForMeal: elder.noteForMeal,
          note: elder.note,
          deliveryGroupId: elder.deliveryGroupId,
          deliveryGroupSortOrder: elder.sortOrder,
          total,
          totalCost,
          deliveryStaffFee: 0,
          category: '送餐',
          paymentMethod: 'PAID',
          source: 'admin',
        });
      }
    });
  }
  return {
    toCreateOrders,
    elders,
    toCreateRecurringOrder: data,
  };
};
