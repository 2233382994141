import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import { Colors } from '@silvergatedelivery/constants';
import Loading from 'components/Loading';
import DateRangeSelector from 'components/DateRangeSelector';
import MealStatisticsTable from './components/MealStatisticsTable';
import RestaurantMealTagSelect from './components/RestaurantMealTagSelect';
import MealStatisticsSettingsToolbar from './components/MealStatisticsSettingsToolbar';
import { getDeliveryStaffIdSchema } from 'forms/schemas';
import {
  getOrders,
  getStartEndByPeriodName,
} from './helpers';
import cache from 'utilities/cache';
import RestaurantMealTagSelectDialog from './components/RestaurantMealTagSelectDialog';
import OrderTable from 'components/OrderTable';

const mealSlotOptions = [{
  label: '午餐',
  value: 'lunch',
}, {
  label: '晚餐',
  value: 'dinner',
}];

const periodOptions = [{
  label: '本日',
  value: 'day',
}, {
  label: '昨日',
  value: 'day/-1/-1',
}, {
  label: '明日',
  value: 'day/1/1',
}];

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: Colors.background.light,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  filterContainer: {
    padding: theme.spacing(2),
    marginBottom: 12,
  },
  buttonGroup: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    'marginLeft': 36,
  },
}));

export default function MealStatistics({ clientId: inClientId }) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState('day'); // day, week, month, year
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [progressDate, setProgressDate] = useState('');
  const [mealSlots, setMealSlots] = useState([]);
  const [deliveryStaffs, setDeliveryStaffs] = useState([]);
  const [orders, setOrders] = useState([]);
  // const [restaurantMealOptions, setRestaurantMealOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [restaurantMealTagList, setRestaurantMealTagList] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [displayOrderIds, setDisplayOrderIds] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState();

  const clientId = inClientId || cache.get('app:facilityId');

  const setCustomPeriod = ({ startDate, endDate }) => {
    setPeriod(`month/0/0/${startDate}/${endDate}`);
  };

  const processOrders = (orders, allDeliveryStaffs) => {
    const data = [];
    const orderDeliveryStaffList = [];
    orders.forEach(({ deliveryStaffId }) => {
      if (deliveryStaffId) {
        if (orderDeliveryStaffList.findIndex((orderDeliveryStaff) => orderDeliveryStaff.id === deliveryStaffId) === -1) {
          const deliveryStaff = allDeliveryStaffs.find(({ id }) => id === deliveryStaffId);
          if (deliveryStaff) {
            orderDeliveryStaffList.push(deliveryStaff);
          } else {
            orderDeliveryStaffList.push({
              id: deliveryStaffId,
              name: '已停用或停止合作的大使',
            });
          }
        }
      } else {
        if (orderDeliveryStaffList.findIndex((orderDeliveryStaff) => orderDeliveryStaff.id === 'N/A') === -1) {
          orderDeliveryStaffList.push({
            id: 'N/A',
            name: '未指定送餐大使',
          });
        }
      }
    });
    let rowIndex = 0;
    restaurantMealTagList.forEach(({ restaurantId, restaurantName, mealItemName, tags }) => {
      const deliveryStaffMealCountList = orderDeliveryStaffList.map(({ name, id }) => ({
        id,
        name,
        count: 0,
        orderIds: [],
      }));
      const row = {
        index: rowIndex,
        restaurantId: restaurantId,
        restaurantName: restaurantName,
        mealItemName: mealItemName,
        tags,
        deliveryStaffMealCountList,
        total: 0,
        orderIds: [],
      };
      rowIndex += 1;
      data.push(row);
    });
    orders.forEach((order) => {
      order.mealItems.forEach((mealItem) => {
        const index = data.findIndex(({ restaurantId, mealItemName, tags }) => {
          let tagMatched = true;
          if (tags && tags.length !== 0) {
            if (order.elder?.tags?.items) {
              const elderTags = order.elder?.tags?.items.filter(({ tag }) => tag.subcategory === '飲食').map(({ tag }) => tag.label);
              if (!tags.every((tag) => elderTags.includes(tag))) {
                tagMatched = false;
              }
            }
          }
          return restaurantId === order.restaurantId && mealItem.name === mealItemName && tagMatched;
        });
        if (index === -1) {
          const deliveryStaffMealCountList = orderDeliveryStaffList.map(({ name, id }) => ({
            id,
            name,
            count: 0,
            orderIds: [],
          }));
          const row = {
            index: rowIndex,
            restaurantId: order.restaurantId,
            restaurantName: order.restaurant.name,
            mealItemName: mealItem.name,
            deliveryStaffMealCountList,
            total: mealItem.quantity,
            orderIds: [],
          };
          const deliveryStaffIndex = row.deliveryStaffMealCountList.findIndex(({ id }) => {
            return order.deliveryStaffId ? id === order.deliveryStaffId : id === 'N/A';
          });
          row.deliveryStaffMealCountList[deliveryStaffIndex].count += mealItem.quantity;
          if (!row.orderIds.includes(order.id)) {
            row.orderIds.push(order.id);
          }
          if (!row.deliveryStaffMealCountList[deliveryStaffIndex].orderIds.includes(order.id)) {
            row.deliveryStaffMealCountList[deliveryStaffIndex].orderIds.push(order.id);
          }
          rowIndex += 1;
          data.push(row);
        } else {
          const row = data[index];
          const deliveryStaffIndex = row.deliveryStaffMealCountList.findIndex(({ id }) => {
            return order.deliveryStaffId ? id === order.deliveryStaffId : id === 'N/A';
          });
          row.total += mealItem.quantity;
          row.deliveryStaffMealCountList[deliveryStaffIndex].count += mealItem.quantity;
          if (!row.orderIds.includes(order.id)) {
            row.orderIds.push(order.id);
          }
          if (!row.deliveryStaffMealCountList[deliveryStaffIndex].orderIds.includes(order.id)) {
            row.deliveryStaffMealCountList[deliveryStaffIndex].orderIds.push(order.id);
          }
        }
      });
    });
    setData(data);
  };

  useEffect(() => {
    if (!clientId) return;

    (async () => {
      setIsLoading(true);

      const {
        startFormat,
        endFormat,
      } = getStartEndByPeriodName(period);

      setStartDate(startFormat);
      setEndDate(endFormat);

      const onUpdateLastItem = (item, nextToken) => {
        if (nextToken) {
          setProgressDate(item.date);
        } else {
          setProgressDate('');
        }
      };

      const orders = (await getOrders(clientId, startFormat, endFormat, onUpdateLastItem)).filter(({ status }) => status !== 'cancelled');
      let allDeliveryStaffs = deliveryStaffs;
      if (deliveryStaffs.length === 0) {
        // TODO: 有可能會少掉已經停用的大使
        allDeliveryStaffs = await getDeliveryStaffIdSchema(null, null, clientId,
          true, true, true, true, false);
        setDeliveryStaffs(allDeliveryStaffs);
      }

      // const restaurantMealOptions = [];
      const tagOptions = [];
      orders.forEach(({ restaurant, mealItems, elder }) => {
        // const index = restaurantMealOptions.findIndex(({ restaurantId }) => restaurantId === restaurant.id);
        // if (index === -1) {
        //   restaurantMealOptions.push({
        //     restaurantId: restaurant.id,
        //     restaurantName: restaurant.name,
        //     mealItems: mealItems.map(({ name }) => name),
        //   });
        // } else {
        //   mealItems.forEach(({ name }) => {
        //     if (!restaurantMealOptions[index].mealItems.includes(name)) {
        //       restaurantMealOptions[index].mealItems.push(name);
        //     }
        //   });
        // }

        if (elder?.tags?.items) {
          elder?.tags?.items.forEach(({ tag }) => {
            if (tag.subcategory === '飲食') {
              if (tagOptions.findIndex(({ id }) => id === tag.id) === -1) {
                tagOptions.push({
                  id: tag.id,
                  label: tag.label,
                });
              }
            }
          });
        }
      });
      setOrders(orders);
      // setRestaurantMealOptions(restaurantMealOptions);
      setTagOptions(tagOptions);
      setIsLoading(false);
    })();
  }, [period, clientId, lastUpdatedAt]);

  useEffect(() => {
    if (mealSlots.length === 0 || mealSlots.length === 2) {
      processOrders(orders, deliveryStaffs);
    } else {
      const filteredOrders = orders.filter(({ mealSlot }) => mealSlots[0] === mealSlot);
      processOrders(filteredOrders, deliveryStaffs);
    }
  }, [mealSlots, orders, deliveryStaffs, restaurantMealTagList]);

  const onAddRestaurantMealTag = (value) => {
    setRestaurantMealTagList([...restaurantMealTagList, value]);
    setDirty(true);
  };

  const onUpdateRestaurantMealTag = (value) => {
    setRestaurantMealTagList(value);
    setDirty(false);
  };

  const onRowUp = (index) => {
    if (index > 0 && index < restaurantMealTagList.length) {
      [restaurantMealTagList[index - 1], restaurantMealTagList[index]] = [restaurantMealTagList[index], restaurantMealTagList[index - 1]];
      setRestaurantMealTagList([...restaurantMealTagList]);
      setDirty(true);
    }
  };

  const onRowDown = (index) => {
    if (index >= 0 && index < restaurantMealTagList.length - 1) {
      [restaurantMealTagList[index], restaurantMealTagList[index + 1]] = [restaurantMealTagList[index + 1], restaurantMealTagList[index]];
      setRestaurantMealTagList([...restaurantMealTagList]);
      setDirty(true);
    }
  };

  const onRowEdit = (value) => {
    setSelectedIndex(value);
    setShowEditDialog(true);
  };

  const onRowDelete = (index) => {
    if (window.confirm('確認刪除該項目?')) {
      const newRestaurantMealTagList = restaurantMealTagList.filter((_, i) => i !== index);
      setRestaurantMealTagList(newRestaurantMealTagList);
      setDirty(true);
    }
  };

  return (
    <Container maxWidth={false} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.filterContainer}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <MealStatisticsSettingsToolbar restaurantMealTagList={restaurantMealTagList} onUpdate={onUpdateRestaurantMealTag} dirty={dirty}/>
              </Grid>
              <Grid item xs={12} container spacing={2} alignItems="center">
                <DateRangeSelector
                  startDate={startDate}
                  endDate={endDate}
                  onUpdate={setCustomPeriod}
                />
                <div className={classes.buttonGroup}>
                  <ButtonGroup color="primary" aria-label="outlined primary button group">
                    {mealSlotOptions.map((item, index)=>(
                      <Button
                        key={index}
                        disabled={isLoading}
                        variant={mealSlots.includes(item.value) ? 'contained':'outlined'}
                        onClick={() => {
                          if (mealSlots.includes(item.value)) {
                            const index = mealSlots.indexOf(item.value);
                            mealSlots.splice(index, 1);
                            setMealSlots([...mealSlots]);
                          } else {
                            mealSlots.push(item.value);
                            setMealSlots([...mealSlots]);
                          }
                        }}
                      >
                        {item.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
                <div className={classes.buttonGroup}>
                  <ButtonGroup color="primary" aria-label="outlined primary button group">
                    {periodOptions.map((item, index)=>(
                      <Button
                        key={index}
                        disabled={isLoading}
                        variant={period === item.value ? 'contained':'outlined'}
                        onClick={() => setPeriod(item.value)}
                      >
                        {item.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              </Grid>
              <Grid item xs={12}>
                <RestaurantMealTagSelect tagOptions={tagOptions} onUpdate={onAddRestaurantMealTag}/>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container>
        {isLoading &&
          <>
            <Loading fullScreen={false} />
            <Typography variant="body1" color="textPrimary" style={{ flex: 1, textAlign: 'center' }}>
              {progressDate && `讀取進度：${progressDate}，請稍候...`}
            </Typography>
          </>}
        {!isLoading &&
          <Grid item xs={12}>
            <MealStatisticsTable
              data={data}
              onRowUp={onRowUp}
              onRowDown={onRowDown}
              onRowEdit={onRowEdit}
              onRowDelete={onRowDelete}
              onCellClick={(value) => setDisplayOrderIds(value?.orderIds || [])}
              onRefresh={() => {
                setLastUpdatedAt(Date.now());
              }}
            />
          </Grid>
        }
        {displayOrderIds.length !== 0 &&
          <Grid item xs={12}>
            <OrderTable title='餐點訂單' showElderTags orderIds={displayOrderIds} />
          </Grid>
        }
      </Grid>
      <RestaurantMealTagSelectDialog
        open={showEditDialog}
        tagOptions={tagOptions}
        data={restaurantMealTagList[selectedIndex]}
        onClose={() => {
          setShowEditDialog(false);
        }}
        onUpdate={(value) => {
          restaurantMealTagList[selectedIndex] = value;
          setRestaurantMealTagList([...restaurantMealTagList]);
          setDirty(true);
        }}
      />
    </Container>
  );
}

MealStatistics.propTypes = {
  clientId: PropTypes.string,
};
