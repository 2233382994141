import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { getRecurringOrder } from 'graphql/queries';
import {
  updateRecurringOrder,
} from 'graphql/mutations';
import {
  getDeliveryStaffIdSchema,
  getRestaurantIdSchema,
  getElderIdSchema,
} from 'forms/schemas';
import uiSchema from './AdminRecurringOrderForm.uiSchema';
import { toastr } from 'react-redux-toastr';
import cache from 'utilities/cache';
import { processNewRecurringOrderSetting } from './helpers';

const defaultFormData = {};

export default function AdminRecurringOrderForm({ formData: inFormData, editMode='group', mode='add', ...props }) {
  const { t } = useTranslation();
  const [onlyShowFacilityRestaurant, setOnlyShowFacilityRestaurant] = useState(true);
  const [onlyShowFacilityDeliveryStaff, setOnlyShowFacilityDeliveryStaff] = useState(true);
  const dataFormRef = useRef();
  const isInitialRender = useRef(true);

  // load here for translation purpose
  const { default: defaultSchema } = require('./AdminRecurringOrderForm.schema.js');
  const schema = JSON.parse(JSON.stringify(defaultSchema));
  if (mode === 'add') {
    schema.description = '設定週期性排單後，首先會依照設定自動新增最近30天的訂單，每天凌晨會自動新增第31天的訂單，也就是任何時間都會有未來30天的訂單';
  } else {
    schema.description = '請注意，更改週期性排單設定不會影響目前30天內已新增的訂單';
  }
  if (editMode === 'group') {
    delete schema.properties.elderId;
    schema.required.push('deliveryGroupId');
  } else {
    delete schema.properties.deliveryGroupId;
    schema.required.push('elderId');
  }
  const formData = (inFormData || defaultFormData);
  if (!formData.deliveryStaffId) delete formData.deliveryStaffId;

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      if (dataFormRef.current && dataFormRef.current.updateSchema) {
        dataFormRef.current.updateSchema();
      }
    }
  }, [onlyShowFacilityRestaurant, onlyShowFacilityDeliveryStaff]);


  const createFunc = async (data) => {
    return await processNewRecurringOrderSetting(data);
  };

  const updateFunc = async (data) => {
    const hour = parseInt(data.deliveryBy.split(':')[0]);
    data.mealSlot = hour <= 14 ? 'lunch' : 'dinner';
    // workaroud...
    data.mealItems.forEach((mealItem) => {
      if (!mealItem.surcharges) {
        mealItem.surcharges = [];
      }
    });
    const newData = JSON.parse(JSON.stringify(data));
    delete newData.restaurant;
    delete newData.elder;
    delete newData.deliveryGroup;
    toastr.warning('請注意，更改週期性排單設定不會影響目前30天內已新增的訂單，可以按下重置訂單按鈕來重新新增訂單');
    if (newData.endOn === undefined) {
      newData.endOn = null;
    }
    try {
      await request(updateRecurringOrder, { input: newData });
    } catch (e) {
      console.log(e);
    }
    const { data: { getRecurringOrder: recurringOrder } } = await request(getRecurringOrder, { id: data.id });
    return recurringOrder;
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyShowFacilityRestaurant}
                value={onlyShowFacilityRestaurant}
                onChange={(e) => {
                  setOnlyShowFacilityRestaurant(e.target.checked);
                }}
                name={'onlyShowOrgRestaurant'}
                color="primary"
              />
            }
            label={`只顯示與該機構相關的${t('餐廳')}`}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyShowFacilityDeliveryStaff}
                value={onlyShowFacilityDeliveryStaff}
                onChange={(e) => {
                  setOnlyShowFacilityDeliveryStaff(e.target.checked);
                }}
                name={'onlyShowFacilityDeliveryStaff'}
                color="primary"
              />
            }
            label={`只顯示該機構的${t('送餐大使')}`}
          />
        </Grid>
      </Grid>
      <DataForm
        schema={schema}
        uiSchema={uiSchema}
        createFunc={createFunc}
        updateFunc={updateFunc}
        formData={formData}
        extMappings={[{
          key: 'elderId',
          func: (elderId) => {
            const clientId = cache.get('app:facilityId');
            return getElderIdSchema(elderId, null, clientId, false, true);
          },
        }, {
          key: 'deliveryStaffId',
          func: (deliveryStaffId) => {
            const clientId = cache.get('app:facilityId');
            return getDeliveryStaffIdSchema(deliveryStaffId, null, clientId, true, false, false, true, onlyShowFacilityDeliveryStaff);
          },
        }, {
          key: 'restaurantId',
          func: (restaurantId) => {
            const clientId = cache.get('app:facilityId');
            return getRestaurantIdSchema(restaurantId, null, clientId, false, onlyShowFacilityRestaurant);
          },
        }]}
        ref={dataFormRef}
        {...props}
      />
    </React.Fragment>
  );
}

AdminRecurringOrderForm.propTypes = {
  formData: PropTypes.object,
  editMode: PropTypes.string,
  mode: PropTypes.string,
};
